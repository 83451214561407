
import claim_idl from "./claim_sol_aqua.json";

//export const NETWORK = "mainnet-beta";
// export const NETWORK = "devnet";

// export const SOLANA_HOST = "https://api.metaplex.solana.com/";
// export const SOLANA_HOST = "https://api.mainnet-beta.solana.com/";
export const SOLANA_HOST = "https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30/";

// export const SOLANA_HOST = "https://api.devnet.solana.com/";

// export const API_KEY_MORALIS =
//   "xHMaMANP502yEQcEXwf1lO1AFF89QCXxqNne2JuXTTWuhR77WUaNzPZ6iOFDVWbS";
// export const NETWORK_SOL_MORALIS = "devnet";
export const OWNER_WALLET = 'HGABrgetag7BbZoPSJGR3Y3L2KcXXCDomGX36b82FqEK'
// export const NETWORK_SOL_MORALIS = "mainnet";

export const CLAIM_IDL = claim_idl;
