/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import ClaimPage from './page/ClaimPage';
import { clusterApiUrl } from "@solana/web3.js";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter, TokenPocketWalletAdapter } from "@solana/wallet-adapter-wallets";
// import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import './App.css';
import { WalletModalProvider } from "./ulti";
require("@solana/wallet-adapter-react-ui/styles.css");

function App() {

  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new TokenPocketWalletAdapter(),
    ],
    [network]
  );

  return (
    <div className="App">
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Routes>
              <Route path="/" element={<ClaimPage />} />
              <Route path="/claim" element={<ClaimPage />} />
            </Routes>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>


    </div>
  );
}

export default App;
