/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButtonCus } from "../../ulti";
// import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { Button, Spin, message } from "antd";
import { LoadingOutlined, CopyOutlined } from "@ant-design/icons";
import { PublicKey, Connection, SYSVAR_RENT_PUBKEY, SystemProgram, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { useHookClaim } from './store'
import { SOLANA_HOST, CLAIM_IDL, OWNER_WALLET } from "../utils/const";
import { BN } from "@project-serum/anchor";
import { isMobile } from 'react-device-detect';
import './style.css'

var JSONB = require('json-buffer')
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const connection = new Connection(SOLANA_HOST, {
    commitment: "confirmed",
});


const ClaimPage = () => {
    const [state, actions]: any = useHookClaim();
    const { publicKey }: any = useWallet();
    let account = publicKey?.toString()
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [isClaim, setIsClaim] = useState(false);
    const [objData, setobjData]: any = useState();
    const [amountClaimed, setAmountClaimed]: any = useState(0);

    // connect contract
    let PROGRAM_ID: any;
    if (objData?.claimContract) {
        PROGRAM_ID = new PublicKey(objData?.claimContract);
    }
    const provider: any = new anchor.AnchorProvider(
        connection,
        window?.solana,
        anchor.AnchorProvider.defaultOptions()
    );
    let program: any;
    if (PROGRAM_ID && provider) {
        program = new anchor.Program<any>(CLAIM_IDL, PROGRAM_ID, provider);
    }

    // call api token claim
    const getTokenClaim = () => {
        try {
            let params = {
                address: account
            }
            actions.getTokenClaim(params)
                .then((res: any) => {
                    setobjData(res)
                })
                .catch((err: any) => {
                    setobjData()
                })
        } catch (error) {
            console.log('error call api', error)
        }
    }

    useEffect(() => {
        // if (account) {
        getTokenClaim()
        // }

    }, [account])

    const btnClaim = () => {
        const obj = {
            className: `btn btn-claim`,
            disabled: isClaim ||
                isLoadingClaim ||
                (amountClaimed !== 0 && amountClaimed >= objData?.amount) ||
                objData?.amount === 0
        };
        return obj;
    };

    const getWhitelistPDA = async (): Promise<[any, any]> => {
        // let seed = new PublicKey(OWNER_WALLET)
        return await PublicKey.findProgramAddress(
            [Buffer.from("aquapoolwl"), publicKey?.toBuffer()],
            program.programId
        );
    }

    const getPoolResourcePDA = async () => {
        let seed = new PublicKey(OWNER_WALLET)
        return await PublicKey.findProgramAddress(
            [Buffer.from("aquaclaimpool"), seed?.toBuffer()],
            program.programId
        );
    }
    const getMerkleTreePDA = async () => {
        let seed = new PublicKey(OWNER_WALLET)
        return await PublicKey.findProgramAddress(
            [Buffer.from("aquamerkletree"), seed?.toBuffer()],
            program.programId
        );
    }

    const handleFetch = async () => {
        const [config, y] = await getWhitelistPDA();
        if (program && publicKey) {
            await program?.account?.whitelistInfo?.fetch(config).then((resRow: any) => {
                setAmountClaimed(resRow?.claimedAmount.toString() / LAMPORTS_PER_SOL || 0)
            }).catch((err: any) => {
                console.log('err', err)
                setAmountClaimed(0)
            })
        }
    }

    useEffect(() => {
        if (publicKey && program) {
            handleFetch()
        }

    }, [publicKey, program])

    const handleClaim = async () => {
        try {
            setIsLoadingClaim(true)
            let amount = objData?.amount
            let [userInfoWL, x] = await getWhitelistPDA();
            const [config, y] = await getPoolResourcePDA();
            const [merkleTreePDA, z] = await getMerkleTreePDA();
            let amountConvert = new BN(amount * anchor.web3.LAMPORTS_PER_SOL);
            let sig = objData?.proof
            let arrProof: any = []
            sig.forEach((element: any) => {
                let temp1 = JSONB.parse(element)
                arrProof.push(temp1)
            });

            try {
                let signature = await program?.rpc?.claim(amountConvert, arrProof, {
                    accounts: {
                        whitelist: userInfoWL,
                        poolclaim: config,
                        merkleTree: merkleTreePDA,
                        authority: publicKey,
                        systemProgram: SystemProgram.programId,
                        rent: SYSVAR_RENT_PUBKEY
                    },
                    signers: []

                })
                if (signature) {
                    console.log('signature', signature)
                    const result = await connection.getSignatureStatus(signature, {
                        searchTransactionHistory: true,
                    });
                    if (result.value?.err === null) {
                        setIsLoadingClaim(false);
                        setIsClaim(true);
                        handleFetch()
                        message.success({
                            type: "success",
                            content: "Claim Success",
                            className: "custom-class",
                            duration: 2,
                        });

                    }
                }

            } catch (error: any) {
                setIsLoadingClaim(false)
                message.error({
                    type: "error",
                    content: error?.message || 'An unknown error',
                    className: "custom-class",
                    duration: 2,
                });

            }

        } catch (error: any) {
            setIsLoadingClaim(false)
            message.error({
                type: "error",
                content: error?.message || 'An unknown error',
                className: "custom-class",
                duration: 2,
            });

        }
    }

    const goToHash = (hash: any) => {
        window.open(`https://solscan.io/tx/${hash}`)
    }

    const copiedHandle = (value: any) => {
        navigator.clipboard.writeText(value)
        message.success('Copied!!!');
    }



    return (
        <div className={`${isMobile ? 'wrap-container-mo' : 'wrap-container'}`}>
            <div className="container">
                <div className="logo">
                    <img src="./images/logo.png" alt="" />
                </div>
                <div className="title">Token Claiming Page</div>
                <div className="content-page">
                    <div className="scroll-content">
                        <div className="title-content">
                            introduction
                        </div>
                        <div className="text-content">
                            As part of Aquacity's effort to our metaverse's growth over the years, we have decided to release our secondary token, AQUA which will be based on SOLANA's chain. AQUA will be used primarily as a governing token for our soon to be released Metaverse in which holders will be able to make crucial decision and steer aquacity's boat towards their preferred direction.
                        </div>
                        <div className="title-content">
                            WHAT DOES THIS MEAN?
                        </div>
                        <div className="text-content">
                            As we make this shift into the SOLANA chain, users can acquire AQUA directly from our closed community presales. Alternatively, you can also opt to swap your existing §Aquacity tokens into AQUA at a predetermined rate via this page. Upon swapping your tokens, there will be a vesting period of 3 months before your $Aquacity tokens are converted into AQUA and then sent into your SOL wallet addresses. <br /> <br />
                            As part of our incentives to promote the token swap, we will be providing a predetermined reward of SOLANA tokens debited directly into your wallet based on the amount of $Aquacity tokens exchanged!<br /> <br />
                            However, please take note that this token swap will only be available from the 22nd to the 23rd of April
                            2024. We will not accept anymore token swaps past these dates! <br /> <br />
                            1. Anyone who attempts to send their Aquacity tokens via CEX wallet addresses will have their tokens forfeited with no chance of recovery.
                            <br />
                            2. We will not be liable for any tokens lost during transactions. Please read the rules and regulation carefully before proceeding with your transfer.
                            <br />
                            3. Token swap will only be available from 23rd April 2024 to 24th April 2024.
                            <br />
                            4. Users must provide the correct details including their wallet addresses as Aquacity Metaverse will not be liable for any mistakes made.
                        </div>

                        <div className="title-content">
                            Contract address
                        </div>
                        <div className="text-content sc-address">
                            Aquacity (MATIC)'s contract address: &ensp;
                            {isMobile ? <br /> : ''}
                            <span>
                                {objData?.maticContract ? (
                                    <>
                                        {objData?.maticContract.slice(0, 8) + '...' + objData?.maticContract.slice(-10)}
                                        &ensp;
                                        <CopyOutlined onClick={() => copiedHandle(objData?.maticContract)} />
                                    </>
                                ) : ('N/A')}
                            </span>
                            <br />
                            <br />
                            AQUA (SOLANA)'s contract address: &ensp;
                            {isMobile ? <br /> : ''}
                            <span>
                                {objData?.solanaContract ? (
                                    <>
                                        {objData?.solanaContract.slice(0, 8) + '...' + objData?.solanaContract.slice(-10)}
                                        &ensp;
                                        <CopyOutlined onClick={() => copiedHandle(objData?.solanaContract)} />
                                    </>
                                ) : ('N/A')}
                            </span>
                        </div>
                        <div className="connect-wallet">
                            <WalletMultiButtonCus />
                            {/* <WalletMultiButton /> */}
                        </div>
                        {publicKey ? (
                            <>
                                <div className="title-content">
                                    wallet details
                                </div>
                                <div className="text-content">
                                    <div className="group-address">
                                        <div className="address1">
                                            Aquacity Token's Transferred Wallet
                                        </div>
                                        <div className="line-address">

                                            {objData?.sendWallet ? (
                                                <>
                                                    {objData?.sendWallet.slice(0, 8) + '...' + objData?.sendWallet.slice(-10)}
                                                    &ensp;
                                                    <CopyOutlined onClick={() => copiedHandle(objData?.sendWallet)} />
                                                </>
                                            ) : ('N/A')}
                                        </div>
                                    </div>
                                    <div className="group-address">
                                        <div className="address1">
                                            Aquacity Token's Transferred Hash
                                        </div>
                                        <div className="line-address" >
                                            {objData?.sendTxHash ? (
                                                <>
                                                    <span className="hash" onClick={() => goToHash(objData?.sendTxHash)}>
                                                        {objData?.sendTxHash.slice(0, 8) + '...' + objData?.sendTxHash.slice(-10)}
                                                    </span>
                                                    &ensp;
                                                    <CopyOutlined onClick={() => copiedHandle(objData?.sendTxHash)} />
                                                </>
                                            ) : ('N/A')}
                                        </div>
                                    </div>
                                    <div className="group-address end">
                                        <div className="address1">
                                            Aquacity Token's Transferred Amount
                                        </div>
                                        <div className="line-address">
                                            {objData?.sendAmount || 'N/A'}

                                        </div>
                                    </div>

                                </div>
                            </>
                        ) : ('')}


                        {publicKey ? (
                            <div className="action-claim">
                                <Button
                                    {...btnClaim()}
                                    onClick={() => handleClaim()}
                                >
                                    <>
                                        {isLoadingClaim ? (
                                            <>
                                                Waiting{" "}
                                                <Spin indicator={antIcon} />
                                            </>
                                        ) : (
                                            "Claim"
                                        )}
                                    </>
                                </Button>
                            </div>
                        ) : ('')}

                    </div>

                </div>
            </div>

        </div>
    )
}

export default ClaimPage;