import React from 'react';
import { BaseWalletMultiButton } from './BaseWalletMultiButton';
import type { ButtonProps } from './Button';
const LABELS = {
    'change-wallet': 'Change wallet',
    connecting: 'Connecting ...',
    'copy-address': 'Copy address',
    copied: 'Copied',
    disconnect: 'Disconnect',
    'has-wallet': 'Connect',
    'no-wallet': 'Click here to connect your wallet',
} as const;

export function WalletMultiButtonCus(props: ButtonProps) {
    return <BaseWalletMultiButton {...props} labels={LABELS} />;
}
