/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../constants/index";

import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";


const StoreClaim = createStore({
    initialState: {},
    actions: {
        getTokenClaim:
            (params) =>
                async ({ setState, getState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}/token/claim`, params)
                            .then((res) => {
                                // console.log('res api--->', res)
                                resolve(res?.data?.data)
                            })
                            .catch((err) => {
                                console.log('err api--->', err)
                                reject(err)
                            })
                    });
                },
    },
    name: "StoreClaim",
});

export const useHookClaim = createHook(StoreClaim);
export const Container = createContainer(StoreClaim, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreClaim);
